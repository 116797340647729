import React from "react"
import SEO from "../../components/Layout/Seo"
import tw from "twin.macro"
import styled from "@emotion/styled"

import imageBgFotos from "../../images/fondo_factura.svg"
import imageBackground from "../../images/background1.png"

import imageBackground2 from "../../images/mini-bg-1.png"
import SectionCard from "../../components/Card/SectionCard"
import { StaticImage } from "gatsby-plugin-image"
import WindowContact from "../../components/Layout/WindowContact"
import YouTube from "react-youtube"
import FAIcon from "../../components/Layout/FAIcon"
import { Link } from "gatsby"
import LayoutPage from "../../components/global/Layout"

const Button = styled.button(() => [
  `
@media (min-width: 768px) {
	max-width: 300px;
}
@media (min-width: 1024px) {
	max-width: 300px;
}
width: 100%;
margin-top: 1.25rem;
margin-left: auto;
margin-right: auto;
color: white;
background-color: #646fff;
transition: background-color 0.7s ease;
padding-top: 0.25rem;
padding-bottom: 0.25rem;

&:hover {
	background-color: #8d94f4;
}
-webkit-box-shadow: 0 0 10px 1px #646fff;
box-shadow: 0 0 10px 1px #646fff;
`,
  tw`px-8 py-3 text-base rounded-ultra `,
])

const AcercaPage = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: "#343d55",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <LayoutPage>

          <div
            css={tw`  flex pt-100px lg:pt-150px min-h-screen   mx-auto max-w-content px-8  `}
          >
            <div css={tw` mt-0   pt-0  lg:text-left text-center  `}>
              <div css={tw`lg:pr-24 mt-0   `}>
                <h1
                  css={tw`text-3xl lg:text-4xl mt-10 lg:pt-0  lg:mt-0 text-autogestiones-card   `}
                >
                  Acerca de{" "}
                  <span className="text-gradient-secondary" css={tw`font-bold`}>
                    nosotros
                  </span>
                </h1>
                <p
                  style={{
                    lineHeight: 1.625
                  }}
                  css={tw`text-gray-300 text-base  lg:text-base  font-normal w-full    pt-4`}
                >
                <strong>Autogestiones</strong> ofrece una amplia gama de funciones y módulos que se adaptan a las necesidades de cualquier negocio, desde pequeñas empresas hasta grandes corporaciones.<br />

Características:<br />

<strong>Tecnología avanzada: </strong> utilizamos las últimas tecnologías, como la inteligencia artificial, para ofrecer una gestión eficiente y automatizada.<br />
<strong>Facilidad de uso: Autogestiones</strong> está diseñado para ser fácil de usar, incluso para usuarios sin conocimientos técnicos.<br />
<strong>Acompañamiento: </strong> ofrecemos un servicio de acompañamiento para ayudar a las empresas a implementar y utilizar la plataforma.<br />
<strong>Atención al cliente: </strong> ofrecemos un servicio de atención al cliente excepcional para responder a cualquier pregunta o problema que pueda surgir.<br />
<strong>Prueba AUTOGESTIONES hoy mismo y descubre cómo puede ayudarte a impulsar tu negocio.</strong> </p>
                <div css={tw`lg:mt-8 lg:mt-0  `}>
                  <StaticImage
                    src="../../images/noinviertas.png"
                    width={1150}
                    quality={100}
                    layout="fullWidth"
                    placeholder="none"
                    formats={["auto", "webp", "avif"]}
                    alt="pantalla auto portal"
                  />{" "}
                </div>
              </div>
            </div>
          </div>
        </LayoutPage>
      </div>
    </>
  )
}

export default AcercaPage

export const Head = () => <SEO
  title="Acerca de Autogestiones"
  lang="es-MX"
  description="AUTOGESTIONES es un ecosistema digital global que ayuda a las empresas y negocios de todos los tamaños a mejorar su productividad, eficiencia y crecimiento."
/>
import * as _ from "lodash"
import { Icon } from "@mdi/react"
import tw from "twin.macro"
import { mdiAccount } from "@mdi/js"
import FAIcon from "./FAIcon"
import { StaticImage } from "gatsby-plugin-image"
import imgAvatar from "../../images/defaults/avatar_default.png"
import React from "react"

const SuperAvatar = ({
  name = "",
  size = 38,
  src = undefined,
}) => {
 
  const getInitials = (name: any) => {
    if (!name) return ""
    const arr = name.split(" ")
    const A = arr[0][0].toUpperCase() || ""
    if (!arr[1]) {
      return A
    } else {
      const B = arr[1][0].toUpperCase() || ""
      return `${A}${B}`.trim()
    }
  }

  const initials = getInitials(name)

  return (
    <div
      style={{
        backgroundImage: `url(${src || imgAvatar})`,
        width: size,
        height: size,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
      css={tw`flex rounded-full`}
    ></div>
  )

}

export default SuperAvatar

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Footer from "./Footer"
import { CookiesAlert } from "../Layout/CookiesAlert"
import HeaderLayout from "./Header"
import { EActionPage, useChat } from "../Chat/ChatContext"
import WindowLayout from "../Layout/WindowLayout"
import FieldAGStatic from "../Form/FieldAGStatic"
import FieldAg from "../Form/FieldAG"
import { Formik, Form, FormikHelpers, FormikValues } from "formik"
import tw from "twin.macro"
import styled from "styled-components"
import { authenticate2fa } from "../Chat/api"
import { TwoAuthWindow } from "../auth/TwoAuthWindow"
import { LoginAndRegisterArea } from "../auth/LoginAndRegisterArea"

interface childrenProps {
	children: any
}



const LayoutPage = ({ children }: childrenProps) => {
	/* 	const data = useStaticQuery(graphql`
			query SiteTitleQuery {
				site {
					siteMetadata {
						title
					}
				}
			}
		`) */

	const { actionPage, targetCountry } = useChat()


	return (
		<>
			<HeaderLayout />
			<div>
				<main  >{children}</main>
				<Footer />
				<LoginAndRegisterArea
					key={actionPage || "basic"}

					targetCountryId={targetCountry?.id} />
			</div>
			<CookiesAlert siteId={targetCountry?.iso} />
		</>
	)
}

LayoutPage.propTypes = {
	children: PropTypes.node.isRequired,
}

export default LayoutPage
